import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PageCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { slugToRatesMap } from '../data/ratesTableInfo';
import { platformMap } from '../utils/constants';

/**
* Tag page (/tag/:slug)
*
* Loads all pages for the requested tag incl. pagination.
*
*/
const PagesTag = ({ location, pageContext }) => {
    const { children, ratesPath, canonical_url } = pageContext;
    const platform = slugToRatesMap[ratesPath];
    const data = {
        ghostTag: {
            title: `${platform.platform} vs Alternatives: Earn Interest Rates Comparisons`,
            description: `How ${platform.platform} interest rates compare vs alternatives. APY / APR competitor comparison. Find the best place to earn on your crypto.`,
            canonical_url,
        },
    };
    const tag = data.ghostTag;
    const inactivePlatforms = ['celsius', 'voyager', 'vauld', 'hodlnaut', 'blockfi', 'gemini', 'haru'];
    const isActivePlatform = !inactivePlatforms.some(inactivePlatform => inactivePlatform === platform.key);
    let activePlatforms = children;
    if (isActivePlatform) {
        activePlatforms = children.filter(({ ratesAPath, ratesBPath }) => {
            return !inactivePlatforms.some((inactivePlatform) => {
                return inactivePlatform === slugToRatesMap[ratesAPath].key || inactivePlatform === slugToRatesMap[ratesBPath].key;
            })
        })
    }

    const pages = activePlatforms.map(({ slug, ratesAPath, ratesBPath }) => {
        const ratesA = slugToRatesMap[ratesAPath];
        const ratesB = slugToRatesMap[ratesBPath];
        const title = `${ratesA.platform} vs ${ratesB.platform}: Interest Rates Comparison`;
        // const oppositePlatform = ratesA.platform === platform.platform ? ratesB : ratesA;
        // return { id: slug, slug, title, feature_image: platformMap[oppositePlatform.key].featuredImage };
        return { id: slug, slug, title, imageA: platformMap[ratesA.key].imageUrl, imageB: platformMap[ratesB.key].imageUrl };
    });

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
                image={platformMap[platform.key].featuredImage}
            />
            <Layout location={location}>
                <div className="container">
                    {/* <figure className="post-feature-image">
                        <img src={platformMap[platform.key].featuredImage} alt={`Logo - ${tag.title}`} />
                    </figure> */}
                    <header className="tag-header">
                    {/* <figure className="post-feature-image">
                        <img src={platformMap[platform.key].featuredImage} alt={`Logo - ${tag.title}`} />
                    </figure> */}
                        <h1>
                            {/* <img src={platformMap[platform.key].imageUrl} alt={`Logo - ${tag.title}`} /> */}
                            {tag.title}
                            </h1>
                        {tag.description ? <p>{tag.description}</p> : null }
                    </header>
                    <section className="post-feed">
                        {pages.map((node) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PageCard key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

export default PagesTag

// export const pageQuery = graphql`
//     query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
//         ghostTag(slug: { eq: $slug }) {
//             ...GhostTagFields
//         }
//         allGhostPage(
//             sort: { order: DESC, fields: [published_at] },
//             filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
//             limit: $limit,
//             skip: $skip
//         ) {
//             edges {
//                 node {
//                 ...GhostPageFields
//                 }
//             }
//         }
//     }
// `
